import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 登录
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/Home',
    component: () => import('../views/Home.vue'),
    children: [
      // 用户管理
      {
        path: '/Usermanagement',
        name: '用户',
        component: () => import('../views/User/Usermanagement.vue'),
      },
      // 原材料
      {
        path: '/Purchase',
        name: '原材料库存',
        component: () => import('../views/Chicken/Purchase.vue'),
      },
      // 采购明细
      {
        path: '/RawMaterial',
        name: '采购明细',
        component: () => import('../views/Chicken/RawMaterial.vue'),
      },
      // 配方
      {
        path: '/ViewRecipe',
        name: '配方',
        component: () => import('../views/Chicken/ViewRecipe.vue'),
      },
      // 每日录入
      {
        path: '/allday',
        name: '日常数据录入',
        component: () => import('../views/Chicken/allday.vue'),
      },

      // 汇总
      {
        path: '/SummarySheet',
        name: '汇总',
        component: () => import('../views/Chicken/SummarySheet.vue'),
      },
      // 鸡蛋入库
      {
        path: '/EggManage',
        name: '鸡蛋入库',
        component: () => import('../views/Chicken/EggManage.vue'),
      },

      // 鸡场费用
      {
        path: '/ChickenHouseCost',
        name: '鸡场费用',
        component: () => import('../views/Chicken/ChickenHouseCost.vue'),
      },

      // 鸡场费用
      {
        path: '/FeedDetail',
        name: '饲料耗用明细',
        component: () => import('../views/Chicken/FeedDetail.vue'),
      },
      // 鸡场费用
      {
        path: '/ChickenOld',
        name: '折旧',
        component: () => import('../views/Chicken/ChickenOld.vue'),
      },
      // 原料采购合计
      {
        path: '/PurchaseTotal',
        name: '原料采购合计',
        component: () => import('../views/Chicken/PurchaseTotal.vue'),
      },

      {
        path: '/Today',
        name: '日常生产数据报表',
        component: () => import('../views/Chicken/Today.vue'),
      },
      {
        path: '/Checkbasics',
        name: '鸡场基础数据建立',
        component: () => import('../views/User/Checkbasics.vue'),
      },
      {
        path: '/Checkdetail',
        name: '动保使用明细',
        component: () => import('../views/Chicken/Checkdetail.vue'),
      },
      {
        path: '/Checkdetail',
        name: '动保使用明细',
        component: () => import('../views/Chicken/Checkdetail.vue'),
      },
      {
        path: '/Profit',
        name: '利润分析',
        component: () => import('../views/NEW/Profit.vue'),
      },
      {
        path: '/Monthly',
        name: '月度分析',
        component: () => import('../views/NEW/Monthly.vue'),
      },
      {
        path: '/Cost',
        name: '成本分析',
        component: () => import('../views/NEW/Cost.vue'),
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('../views/test'),
      },
    ],
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
})

export default router
