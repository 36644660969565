<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  data() {
    return {

    };
  },
  mounted() {

  }
}
</script>


<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #f1f1f1;
}

* {
  font-size: 12px !important;
}

.el-table .cell {
  padding: 0 !important;
}

.el-table--mini,
.el-table__cell {
  padding: 0 !important;
}


.tableClass .cell {
  padding: 0 !important;
  height: 20px !important;
  line-height: 20px !important;
  text-align: center;
}


// 导航栏二级菜单宽度
.el-menu-item {
  min-width: 150px !important;
}

.center-table td,
.center-table th,
.el-table__cell {
  text-align: center !important;
}

.el-table--striped .el-table__body tr.el-table__row--striped.el-table__row--striped.el-table__row--striped td {
  background-color: #aff6fd !important;
  /*替换为你需要的颜色，觉得优先级不够就加!important*/
}
</style>
